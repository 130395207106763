import Vue from 'vue'
import Cookie from 'vue-cookies'
import VueGtag from 'vue-gtag'
import Router from 'vue-router'
import Define from './api/define.js'

const routes = [
  {
    path      : `/${Define.PAGE_NAMES.LOGIN_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.LOGIN_PAGE_NAME,
    component : () => import('./views/Login.vue'),
    meta      : {
      bodyClass : 'login'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.TOP_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.TOP_PAGE_NAME,
    component : () => import('./views/Top.vue'),
    meta      : {
      bodyClass : 'home'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CONTACT_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.CONTACT_PAGE_NAME,
    component : () => import('./views/Contact/Contact.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'CONTACT_PAGE_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CONTACT_PAGE_NAME}/item/:id`,
    name      : Define.PAGE_NAMES.ITEM_CONTACT_PAGE_NAME,
    component : () => import('./views/Contact/ItemContact.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'ITEM_CONTACT_PAGE_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CONTACT_PAGE_NAME}/stock/:id`,
    name      : Define.PAGE_NAMES.STOCK_CONTACT_PAGE_NAME,
    component : () => import('./views/Contact/StockContact.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'ITEM_CONTACT_PAGE_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME,
    component : () => import('./views/RegisterMember.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'PAGE_NEW_MEMBER_REGISTRATION'
    }
  },
  {
    path        : `/${Define.PAGE_NAMES.MY_PAGE_PAGE_NAME}`,
    name        : Define.PAGE_NAMES.MY_PAGE_PAGE_NAME,
    redirect : {
      name : Define.PAGE_NAMES.BID_HISTORY_PAGE_NAME
    },
    component   : () => import('./views/MyPage.vue'),
    children    : [
      {
        path      : Define.PAGE_NAMES.CHANGE_INFO_PAGE_NAME,
        name      : Define.PAGE_NAMES.CHANGE_INFO_PAGE_NAME,
        component : () => import('./views/MyPage/ChangeInfo.vue'),
        meta        : {
          requiresAuth : true
        }
      },
      {
        path      : Define.PAGE_NAMES.BID_HISTORY_PAGE_NAME,
        name      : Define.PAGE_NAMES.BID_HISTORY_PAGE_NAME,
        component : () => import('./views/MyPage/BidHistory.vue'),
        meta        : {
          requiresAuth : true
        }
      }
    ],
    meta        : {
      requiresAuth : true
    }
  },

  {
    path      : `/${Define.PAGE_NAMES.REISSUE_PASSWORD_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.REISSUE_PASSWORD_PAGE_NAME,
    component : () => import('./views/ReissuePassword.vue'),
    meta      : {
      headerNameTag : 'LINK_FORGOT_PASSWORD_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME,
    component : () => import('./views/Auction/List.vue'),
    meta      : {
      bodyClass      : 'item_p-list',
      enableQuery    : true,
      headerBidding  : true,
      headerFavorite : true,
      webSocketOn    : true,
      headerNameTag  : 'AUCTION_PAGE_HEADER_LABEL'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME,
    component : () => import('./views/Auction/Detail.vue'),
    meta      : {
      bodyClass      : 'item_p-detail',
      enableQuery    : true,
      headerBidding  : false,
      headerFavorite : false,
      webSocketOn    : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.STOCK_LIST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.STOCK_LIST_PAGE_NAME,
    component : () => import('./views/Stock/List.vue'),
    meta      : {
      bodyClass      : 'item_p-list',
      enableQuery    : true,
      headerFavorite : true,
      headerNameTag  : 'STOCK_PAGE_HEADER_LABEL'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.STOCK_LIST_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.STOCK_DETAIL_PAGE_NAME,
    component : () => import('./views/Stock/Detail.vue'),
    meta      : {
      bodyClass      : 'item_p-detail',
      enableQuery    : true,
      headerFavorite : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.TENDER_LIST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.TENDER_LIST_PAGE_NAME,
    component : () => import('./views/Tender/List.vue'),
    meta      : {
      bodyClass      : 'item_p-list',
      enableQuery    : true,
      headerBidding  : true,
      headerFavorite : true,
      headerNameTag  : 'TENDER_PAGE_HEADER_LABEL'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.TENDER_LIST_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.TENDER_DETAIL_PAGE_NAME,
    component : () => import('./views/Tender/Detail.vue'),
    meta      : {
      bodyClass      : 'item_p-detail',
      enableQuery    : true,
      headerBidding  : true,
      headerFavorite : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.GUILD_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.GUILD_PAGE_NAME,
    component : () => import('./views/Guild.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_BEGINNER_GUIDE'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHERS_PAGE_NAME}/${Define.PAGE_NAMES.PRIVACY_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.PRIVACY_PAGE_NAME,
    component : () => import('./views/Others/Privacy.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'LINK_KOBELCO_KENKI_PRIVACY'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHERS_PAGE_NAME}/${Define.PAGE_NAMES.PROFILE_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.PROFILE_PAGE_NAME,
    component : () => import('./views/Others/Profile.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'LINK_KOBELCO_KENKI_PROFILE'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.FAQ_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.FAQ_PAGE_NAME,
    component : () => import('./views/Others/Faq.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'LINK_GUIDANCE_FAQ'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.ACCESS_PAGE_NAME}/${Define.PAGE_NAMES.ACCESS_SHOP_LIST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.ACCESS_SHOP_LIST_PAGE_NAME,
    component : () => import('./views/Access/UsedShopList.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'LINK_INTRODUCTION_SECONDHAND_CAR_CENTER'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.ACCESS_PAGE_NAME}/${Define.PAGE_NAMES.ACCESS_ICHIKAWA_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.ACCESS_ICHIKAWA_PAGE_NAME,
    component : () => import('./views/Access/Ichikawa.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'LINK_ICHIKAWA_SECONDHAND_CAR_CENTER'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.ACCESS_PAGE_NAME}/${Define.PAGE_NAMES.ACCESS_KOBE_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.ACCESS_KOBE_PAGE_NAME,
    component : () => import('./views/Access/Kobe.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'LINK_KOBE_SECONDHAND_CAR_CENTER'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.ACCESS_PAGE_NAME}/${Define.PAGE_NAMES.ACCESS_YOKOHAMA_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.ACCESS_YOKOHAMA_PAGE_NAME,
    component : () => import('./views/Access/Yokohama.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'LINK_YOKOHAMA_SECONDHAND_CAR_CENTER'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.ANNOUNCEMENT_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.ANNOUNCEMENT_PAGE_NAME,
    component : () => import('./views/Others/Announcement.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'LINK_ANNOUNCEMENT'
    }
  },
  {
    path      : '/system-error',
    name      : Define.PAGE_NAMES.SYSTEM_ERROR_PAGE_NAME,
    component : () => import('./views/SystemError.vue')
  },
  {
    path     : '*',
    name     : Define.PAGE_NAMES.NOT_EXIST_PAGE_NAME,
    redirect : {name : Define.PAGE_NAMES.TOP_PAGE_NAME}
  }
]

const router = new Router({
  // Mode: 'hash', // モック作成時はこっちで実行
  mode : 'history',
  base : process.env.BASE_URL,

  routes
})

Vue.use(Router)
Vue.use(VueGtag, {
  config : {id : process.env.VUE_APP_GA_ID}
}, router)

Vue.prototype.$define = Define
Vue.prototype.$routerGo = (name, id = null, query = {}, hash = null) => {
  const obj = {
    name,
    query
  }
  if (id) {
    obj.params = {id}
  }
  if (hash) {
    obj.hash = `#${hash}`
  }
  router.push(obj).catch(() => {})
}

Vue.prototype.$routerGoNewWindow = (name, id = null, query = {}, hash = null) => {
  const obj = {
    name,
    query
  }
  if (id) {
    obj.params = {id}
  }
  if (hash) {
    obj.hash = `#${hash}`
  }
  const routeData = router.resolve(obj)
  window.open(routeData.href, '_blank')
}

router.beforeEach((to, from, next) => {
  if ($('header p.btnMenu').hasClass('close')) {
    $('header p.btnMenu').trigger('click')
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (Cookie.get(Define.LOCAL_STORE_LABEL.SESSION_TOKEN)) {
      if (to.name !== from.name) {
        window.scrollTo({
          top      : 0,
          left     : 0,
          behavior : 'smooth'
        })
      }
      return next()
    }
    // セッションIDがなければログイン画面へリダイレクトする
    alert('ログインしてください。')
    return next({name : Define.PAGE_NAMES.LOGIN_PAGE_NAME})
  }
  if (to.name !== from.name) {
    window.scrollTo({
      top      : 0,
      left     : 0,
      behavior : 'smooth'
    })
  }
  return next()
})

export default router
