/**
 * 日本語
 */
export default {
  SITE_TITLE                              : 'KOBELCO公式中古建機サイト│KCMJ',
  NAME                                    : '日本語',
  LANGUAGE_CODE                           : 'jp',
  SIDE_NAME                               : '公式中古建機サイト',
  PAGE_TOP                                : 'TOP',
  PAGE_PURCHASE_OF_CONSTRUCTION_EQUIPMENT : '建機購入',
  PAGE_AUCTION                            : 'オークション',
  PAGE_AUCTION_EXCAVATOR                  : 'ショベル他',
  PAGE_AUCTION_CRANE                      : 'クレーン',
  PAGE_TENDER                             : 'テンダー',
  PAGE_TENDER_EXCAVATOR                   : 'ショベル他',
  PAGE_TENDER_CRANE                       : 'クレーン',
  PAGE_INVENTORY_INFORMATION              : '在庫機',
  PAGE_INVENTORY_INFORMATION_EXCAVATOR    : 'ショベル他',
  PAGE_INVENTORY_INFORMATION_CRANE        : 'クレーン',

  HEADER_BIDDING_BUTTON_LABEL             : '入札中',
  HEADER_FAVORITE_LIST_BUTTON_LABEL       : 'お気に入りリスト',
  HEADER_LOGOUT_BUTTON_LABEL              : 'ログアウト',
  PAGE_MEMBER_REGISTRATION                : '会員登録',
  PAGE_MEMBER_REGISTRATION_NAME           : '会員登録申請',
  PAGE_LOGIN                              : 'ログイン',
  PAGE_NEW_MEMBER_REGISTRATION            : '新規会員登録',
  PAGE_MY_PAGE                            : 'マイページ',
  PAGE_BEGINNER_GUIDE                     : 'はじめての方へ',
  PAGE_GUIDE_WEB_AUCTION_FLOW             : 'WEBオークションの流れ',
  PAGE_GUIDE_WEB_AUCTION_TAB1             : 'WEBオークション',
  PAGE_GUIDE_WEB_AUCTION_TAB2             : 'WEBテンダー',
  PAGE_GUIDE_WEB_AUCTION_TAB3             : '在庫機商談',
  PAGE_GUIDE_BID_MEETING_FLOW             : '入札会の流れ',
  LINK_VENUE_ACCESS                       : '中古車センターのご紹介',
  LINK_INTRODUCTION_SECONDHAND_CAR_CENTER : '中古車センターのご紹介',
  LINK_ICHIKAWA_SECONDHAND_CAR_CENTER     : '市川中古車センター',
  LINK_KOBE_SECONDHAND_CAR_CENTER         : '神戸中古車センター',
  LINK_YOKOHAMA_SECONDHAND_CAR_CENTER     : '横浜中古車センター',
  PAGE_GUIDANCE                           : 'ご案内',
  LINK_GUIDANCE_FAQ                       : 'よくある質問',
  PAGE_GUIDANCE_CONTACT                   : 'お問い合わせ',
  LINK_IN_HOUSE_CATALOG                   : '自社カタログ',
  LINK_FOR_JAPANESE_PC                    : '国内のユーザー様は<br>こちらより',
  LINK_FOR_JAPANESE_SP                    : '国内のユーザー様はこちらより',
  LINK_KOBELCO_KENKI_GUILD                : '会社案内',
  LINK_KOBELCO_KENKI_PROFILE              : '会社概要',
  LINK_KOBELCO_KENKI_PRIVACY              : '個人情報の保護',
  LINK_ANNOUNCEMENT                       : '電子公告',
  LINK_ANNOUNCEMENT_DENSI                 : '電子公告',
  LINK_ANNOUNCEMENT_KESSAI                : '決算公告',
  LINK_ANNOUNCEMENT_NOT_FOUND             : '公告がありません。',
  LINK_ANNOUNCEMENT_PDF_NOTICE            : 'PDF形式のファイルをご覧いただくには、Adobe社のAcrobat Readerが必要です。<br>左のバナーよりダウンロードして下さい。',
  LINK_CONFIRM_MEMBERSHIP                 : 'オークション会員規約',
  FOOTER_COPYRIGHT                        : '<small>Copyright© 2021 <br class="only_sp">Kobelco Construction Machinery Co.,Ltd. All Rights Reserved.</small>',
  FOOTER_KOBUTSU                          : '<span>古物商許可番号[コベルコ建機日本（株）] </span><span>441100001519(千葉県公安委員会許可済)</span>',

  LABEL_LOGINID                                 : 'ユーザーID',
  PLACEHOLDER_USERID                            : '',
  LABEL_PASSWORD                                : 'パスワード',
  PLACEHOLDER_PASSWORD                          : '',
  LABEL_USERNAME                                : '担当者名',
  PLACEHOLDER_USERNAME                          : '',
  PS_USERNAME_BEFORE                            : 'ログインする担当者さまのお名前をご記入ください。',
  PS_USERNAME_AFTER                             : 'ログイン後、担当者別で履歴確認することができます。',
  LABEL_SAVE_ID_PASSWORD_USERNAME               : 'ID・パスワード・担当者名を保存',
  LINK_FORGOT_PASSWORD                          : 'パスワードを忘れた方はコチラ',
  LINK_FORGOT_PASSWORD_NAME                     : 'パスワードをお忘れの方',
  LABEL_PARTICIPATION_TERMS                     : '参加規約',
  LABEL_AGREE_TERMS_PARTICIPATION               : '参加規約に同意する',
  LINK_NEW_MEMBER_REGISTRATION_APPLICATION_FREE : '新規会員登録申請(無料)',
  PS_MEMBERSHIP_REGISTRATION_REQUIRED           : '※建機の価格を見るには会員登録が必要です。',
  OTHER_LANGUAGE                                : 'English',
  OTHER_LANGUAGE_SHOT                           : 'EN',
  OTHER_LANGUAGE_CODE                           : 'en',
  DATE_FORMAT                                   : 'YYYY/MM/DD(dd)',
  DATE_TIME_FORMAT                              : 'YYYY/MM/DD HH:mm',
  ROW_BID_DATE_TIME_FORMAT                      : 'MM/DD HH:mm',

  TEXT_FORMAT       : '^([^\x01-\x7E]|[0-9a-zA-Z -/:-@\[-~])+$',
  TEXT_FORMAT_ERROR : '半角英数字・全角文字・記号で入力してください。',

  CURRENT_PASSWORD_ERROR_MESSAGE : 'パスワードの変更時は、現在のパスワードを入力してください。',
  PASSWORD_CONFIRM_ERROR_MESSAGE : 'パスワードとパスワード(確認用)が一致していません。',
  MAIL_CONFIRM_ERROR_MESSAGE     : 'メールアドレスとメールアドレス(確認用)が一致していません。',

  NO_RECOMMEND_ITEM_IN_SESSION_MESSAGE  : '現在{names}を開催中です',
  NO_RECOMMEND_ITEM_SCHEDULED_MESSAGE   : '次回のオークション公開は、<br>{start_date}～の予定です<br>どうぞ楽しみにお待ちください',
  NO_RECOMMEND_ITEM_NO_SCHEDULE_MESSAGE : '次回のオークション公開をどうぞお楽しみにお待ちください',

  TOP_PAGE_AUCTION_RECOMMEND_ITEMS_VIEW_HEADER            : 'WEB オークション/テンダー出展機',
  TOP_PAGE_AUCTION_NEW_ITEMS_VIEW_HEADER                  : '在庫機　新着',
  TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_PREFIX_LABEL : 'オークション',
  TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_SUBFIX_LABEL : '出展機一覧',
  TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_PREFIX_LABEL  : 'テンダー',
  TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_SUBFIX_LABEL  : '出展機一覧',
  TOP_PAGE_CRANE_LIST_BUTTON_PREFIX_LABEL                 : 'クレーン',
  TOP_PAGE_CRANE_LIST_BUTTON_SUBFIX_LABEL                 : '一覧',
  TOP_PAGE_AUCTION_NEW_ITEMS_BUTTON_LABEL                 : '一覧',
  TOP_PAGE_INVENTORY_MACHINE_LIST_BUTTON_LABEL            : '在庫機一覧',
  TOP_PAGE_LIST_OF_CRANE_STOCK_MACHINES_BUTTON_LABEL      : 'クレーン在庫機一覧',
  TOP_PAGE_AUCTION_SCHEDULE_VIEW_HEADER                   : '入札会スケジュール',
  TOP_PAGE_AUCTION_STATUS_AUCTION_IN_SESSION_LABEL        : '開催中',
  TOP_PAGE_AUCTION_STATUS_AUCTION_WILL_BE_HELD_LABEL      : '開催予定',
  TOP_PAGE_AUCTION_STATUS_AUCTION_ENDED_LABEL             : '開催終了',
  TOP_PAGE_ITEM_MODEL_LABEL                               : 'モデル',
  TOP_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL                 : '号機',
  TOP_PAGE_ITEM_MODEL_YEAR_LABEL                          : '年式',
  TOP_PAGE_NOTICES_VIEW_HEADER                            : 'お知らせ',
  TOP_PAGE_FOR_NEWBIE_LABEL                               : 'はじめての方へ',
  TOP_PAGE_AUCTION_GUIDE_LINK_LABEL                       : 'WEB オークション/テンダー/商談の流れ',
  TOP_PAGE_PRICE_CURRENCY_MARK                            : '￥',

  MEMBER_REQUEST_DOCUMENT_EXPLANATION_MESSAGE           : ' 会員ご登録申請にあたり当社にて審査を行っています。</br>以下からダウンロードいただき、必要書類をPDF（JPEG/PNGも可）にてご用意お願いします。',
  MEMBER_REQUEST_DOCUMENT_CAUTION_MESSAGE               : '※個人名義で会員登録される場合、2は会社名でなく個人名での留意事項への記載、3は免許証など個人の身分証明書のコピーをご提出下さい。',
  MEMBER_REQUEST_DOCUMENT_TITLE                         : '会員申請必要書類',
  MEMBER_REQUEST_DOWNLOAD_SAMPLE_DOCUMENT_BUTTON_LABEL  : '申請必要書類ダウンロード',
  MEMBER_REQUEST_FORM_CAUTION_TITLE                     : '【注意事項】日本国内の建設機械ユーザー様へ',
  MEMBER_REQUEST_FORM_CAUTION_MESSAGE                   : '<span class="fontB">【注意事項】日本国内の建設機械ユーザー様へ</span><br> コベルコ建機日本（株）中古車・レンタル本部では、日本国内の建設機械ユーザー様へ直接販売はしておりません。<br> ご購入をご希望の方は<a href="https://www.kobelco-kenki.co.jp/" target="_blank">販売サービス窓口（コベルコ建機のホームページへ移動します）</a>からお問い合わせください。',
  MEMBER_REQUEST_FORM_ENTRY_FORM_INFO_MESSAGE           : '必要書類がご用意できましたら、以下のフォーム項目をご入力ください。<br><em class="req">必須</em>のついている項目は必ずご入力ください。',
  MEMBER_REQUEST_FORM_COUNTRY_LABEL                     : '国<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_COMPANY_NAME_LABEL                : '会社名・団体名<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_REPRESENTATIVE_DIRECTOR_LABEL     : '代表者氏名',
  MEMBER_REQUEST_FORM_BRANCH_OFFICE_LABEL               : '支社・営業所名',
  MEMBER_REQUEST_FORM_USER_NAME_LABEL                   : '担当者氏名<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_POST_CODE_LABEL                   : '郵便番号',
  MEMBER_REQUEST_FORM_ADDRESS_LABEL                     : '住所<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_REQUIRED_LABEL                    : '必須',
  MEMBER_REQUEST_FORM_PREFECTURES_LABEL                 : '都道府県',
  MEMBER_REQUEST_FORM_MUNICIPALITIES_LABEL              : '市区町村',
  MEMBER_REQUEST_FORM_LOCATION_LABEL                    : '所在地',
  MEMBER_REQUEST_FORM_LOCATION_LABEL_EN                 : '住所',
  MEMBER_REQUEST_FORM_TEL_LABEL                         : '電話番号<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_FAX_LABEL                         : 'FAX番号',
  MEMBER_REQUEST_FORM_EMAIL_LABEL                       : 'メールアドレス<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_CONFIRM_EMAIL_LABEL               : 'メールアドレス<br class="only_pc">(確認用)<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_PASSWORD_LABEL                    : 'パスワード<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_CONFIRM_PASSWORD_LABEL            : 'パスワード(確認用)<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_PASSWORD_RULE                     : '<p class="kome">※パスワードは8文字以上、14文字以内で英字、数字を含めて指定してください。</p>',
  MEMBER_REQUEST_FORM_ANTIQUE_FILE_LABEL                : '貴社古物商許可書<br class="only_pc">のコピー<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_EXPORT_FILE_LABEL                 : '署名済み輸出管理<br class="only_pc">に関する<br class="only_sp">留意事項<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_NAME_CARD_FILE_LABEL              : '代表者様の<br class="only_pc">名刺コピー<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_AGREE_LABEL                       : '個人情報の<br class="only_pc">取り扱いについて<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_MEMBERSHIP_AGREE_LABEL            : 'オークションの<br class="only_pc">会員規約について<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL              : '同意する',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1   : '入力内容を送信いただく前に、必ず「',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2   : '個人情報の保護',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2_1 : 'オークション会員規約',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3   : '」をご覧ください。',
  MEMBER_REQUEST_FORM_FILE_SELECT_BUTTON_LABEL          : 'ファイルを選択',
  MEMBER_REQUEST_FORM_FILE_DELETE_BUTTON_LABEL          : '削除',
  MEMBER_REQUEST_FORM_FILE_UNSELECTED_MESSAGE           : 'ファイルが選択されていません',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_MESSAGE       : '<p class="kome">※PDF/JPEG/PNG:5MB以内</p><p class="kome">※ファイル名は半角英数字で設定の上、添付お願い致します。</p>',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_NONJP         : '<p class="kome">※英語の名刺の添付をお願い致します。</p>',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_HEADER        : '申請必要書類',
  MEMBER_REQUEST_FORM_SUBMIT_LABEL                      : '入力内容を確認する',
  MEMBER_REQUEST_FORM_SEARCH_POST_CODE_BUTTON_LABEL     : '検索',
  MEMBER_REQUEST_FORM_FULL_WIDTH_LABEL                  : '[全角]',
  MEMBER_REQUEST_FORM_FULL_WIDTH_NUMBERS_LABEL          : '[半角数字]',
  MEMBER_REQUEST_FORM_FULL_WIDTH_ALPHANUMERICAL_LABEL   : '[半角英数字]',
  MEMBER_REQUEST_FORM_POST_CODE_PLACEHOLDER             : '',
  MEMBER_REQUEST_FORM_ADDRESS_PLACEHOLDER               : '',
  MEMBER_REQUEST_FORM_PREFECTURES_PLACEHOLDER           : '',
  MEMBER_REQUEST_FORM_MUNICIPALITIES_PLACEHOLDER        : '',
  MEMBER_REQUEST_FORM_LOCATION_PLACEHOLDER              : '',
  MEMBER_REQUEST_FORM_PHONE_NUMBER_PLACEHOLDER          : '',
  MEMBER_REQUEST_FORM_FAX_NUMBER_PLACEHOLDER            : '',
  MEMBER_REQUEST_FORM_WITHDRAW_LABEL                    : '退会する',
  MEMBER_REQUEST_TEXT_FORMAT_ERROR                      : '半角英数字・記号で入力してください。',
  MEMBER_EDIT_FORM_FILE_CHANGING_LABEL                  : '※変更がある場合はお手数ですがお問い合わせください',
  MEMBER_EDIT_FORM_EXPORT_FILE_LABEL                    : '貴社古物商許可書<br class="only_pc">のコピー',
  MEMBER_EDIT_FORM_ANTIQUE_FILE_LABEL                   : '署名済み輸出管理<br class="only_pc">に関する<br class="only_sp">留意事項',
  MEMBER_EDIT_FORM_NAME_CARD_FILE_LABEL                 : '代表者様の<br class="only_pc">名刺コピー',

  MEMBER_REQUEST_CONFIRM_EXPLANATION_MESSAGE           : '入力項目をご確認いただき、お間違いがなければ「送信」をクリックしてください。',
  MEMBER_REQUEST_CONFIRM_COUNTRY_LABEL                 : '国',
  MEMBER_REQUEST_CONFIRM_COMPANY_NAME_LABEL            : '会社名・団体名',
  MEMBER_REQUEST_CONFIRM_REPRESENTATIVE_DIRECTOR_LABEL : '代表者氏名',
  MEMBER_REQUEST_CONFIRM_BRANCH_OFFICE_LABEL           : '支社・営業所名',
  MEMBER_REQUEST_CONFIRM_USER_NAME_LABEL               : '担当者氏名',
  MEMBER_REQUEST_CONFIRM_POST_CODE_LABEL               : '郵便番号',
  MEMBER_REQUEST_CONFIRM_ADDRESS_LABEL                 : '住所',
  MEMBER_REQUEST_CONFIRM_PREFECTURES_LABEL             : '都道府県',
  MEMBER_REQUEST_CONFIRM_MUNICIPALITIES_LABEL          : '市区町村',
  MEMBER_REQUEST_CONFIRM_LOCATION_LABEL                : '所在地',
  MEMBER_REQUEST_CONFIRM_TEL_LABEL                     : '電話番号',
  MEMBER_REQUEST_CONFIRM_FAX_LABEL                     : 'FAX番号',
  MEMBER_REQUEST_CONFIRM_EMAIL_LABEL                   : 'メールアドレス',
  MEMBER_REQUEST_CONFIRM_CONFIRM_EMAIL_LABEL           : 'メールアドレス<br class="only_pc">(確認用)',
  MEMBER_REQUEST_CONFIRM_PASSWORD_LABEL                : 'パスワード',
  MEMBER_REQUEST_CONFIRM_CONFIRM_PASSWORD_LABEL        : 'パスワード(確認用)',
  MEMBER_REQUEST_CURRENT_PASSWORD_LABEL                : '現在のパスワード',
  MEMBER_REQUEST_CONFIRM_EXPORT_FILE_LABEL             : '貴社古物商許可書<br class="only_pc">のコピー',
  MEMBER_REQUEST_CONFIRM_ANTIQUE_FILE_LABEL            : '署名済み輸出管理<br class="only_pc">に関する<br class="only_sp">留意事項',
  MEMBER_REQUEST_CONFIRM_NAME_CARD_FILE_LABEL          : 'ご担当者様の<br class="only_pc">名刺コピー',
  MEMBER_REQUEST_CONFIRM_AGREE_LABEL                   : '個人情報の<br class="only_pc">取り扱いについて',
  MEMBER_REQUEST_CONFIRM_MEMBERSHIP_AGREE_LABEL        : 'オークションの<br class="only_pc">会員規約について',
  MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL             : '戻る',
  MEMBER_REQUEST_CONFIRM_SEND_BUTTON_LABEL             : '送信する',

  MEMBER_REQUEST_COMPLETION_MESSAGE                  : '会員申請完了しました。',
  MEMBER_REQUEST_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL : 'ログイン画面に戻る',

  MEMBER_REQUEST_FORM_TANTO1_LABEL           : '担当者1（代表者）',
  MEMBER_REQUEST_FORM_TANTO2_LABEL           : '担当者2',
  MEMBER_REQUEST_FORM_TANTO3_LABEL           : '担当者3',
  MEMBER_REQUEST_FORM_TANTO4_LABEL           : '担当者4',
  MEMBER_REQUEST_FORM_TANTO5_LABEL           : '担当者5',
  MEMBER_REQUEST_FORM_TANTO6_LABEL           : '担当者6',
  MEMBER_REQUEST_FORM_TANTO7_LABEL           : '担当者7',
  MEMBER_REQUEST_FORM_TANTO8_LABEL           : '担当者8',
  MEMBER_REQUEST_FORM_TANTO9_LABEL           : '担当者9',
  MEMBER_REQUEST_FORM_TANTO10_LABEL          : '担当者10',
  MEMBER_REQUEST_FORM_TANTO_EMAIL            : 'メールアドレス',
  MEMBER_REQUEST_FORM_TANTO_NAME             : '氏名',
  MEMBER_REQUEST_TEXT_REQUIRED_ERROR         : '入力してください。',
  MEMBER_REQUEST_TEXT_DUPLICATE_ERROR        : 'メールアドレスが重複しています。',
  MEMBER_REQUEST_FORM_TANTO_ADD_BUTTON_LABEL : '担当者を追加する',

  REISSUE_PASSWORD_EXPLANATION_MESSAGE                 : 'パスワードをお忘れの方は登録したIDとメールアドレスを記入してください。<br>「送信」ボタンを押しますと、パスワードが登録メールアドレスに届きます。',
  REISSUE_PASSWORD_CAUTION_MESSAGE                     : 'IDもお忘れの場合は各種お問い合わせからお知らせください。',
  REISSUE_PASSWORD_FORM_ID_LABEL                       : 'ID',
  REISSUE_PASSWORD_FORM_EMAIL_LABEL                    : 'メールアドレス',
  REISSUE_PASSWORD_FORM_CONFIRM_EMAIL_LABEL            : 'メールアドレス(確認用)',
  REISSUE_PASSWORD_FORM_ID_PLACEHOLDER                 : '半角英数字',
  REISSUE_PASSWORD_FORM_EMAIL_PLACEHOLDER              : '半角英数字',
  REISSUE_PASSWORD_FORM_SUBMIT_LABEL                   : '送信する',
  REISSUE_PASSWORD_COMPLETION_MESSAGE                  : '新しいパスワードをメールアドレス宛に送信いたしました。',
  REISSUE_PASSWORD_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL : 'ログイン画面に戻る',

  AUCTION_PAGE_SEARCH_HEADER : 'オークション出展機　検索条件',
  AUCTION_PAGE_HEADER_LABEL  : 'オークション出展機',
  TENDER_PAGE_SEARCH_HEADER  : 'テンダー出展機　検索条件',
  TENDER_PAGE_HEADER_LABEL   : 'テンダー出展機',
  STOCK_PAGE_SEARCH_HEADER   : '在庫機　検索条件',
  STOCK_PAGE_HEADER_LABEL    : '在庫機',

  AUCTION_PAGE_SEARCH_TYPE : 'オークション',
  TENDER_PAGE_SEARCH_TYPE  : 'テンダー',
  STOCK_PAGE_SEARCH_TYPE   : '在庫機',

  ITEM_SEARCH_KEYWORD_LABEL                          : 'キーワード',
  ITEM_SEARCH_KEYWORD_PLACEHOLDER                    : '例)SK200',
  ITEM_SEARCH_INVENTORY_AREA_LABEL                   : '在庫地',
  ITEM_SEARCH_CATEGORY_LABEL                         : 'カテゴリ',
  ITEM_SEARCH_MODEL_YEAR_LABEL                       : '年式',
  ITEM_SEARCH_PRICE_LABEL                            : '価格',
  ITEM_SEARCH_PRICE_UNIT                             : '万',
  ITEM_SEARCH_FAVORITE_LABEL                         : 'お気に入り',
  ITEM_SEARCH_BIDDING_LABEL                          : '入札中',
  ITEM_SEARCH_EXCEEDING_THE_LOWEST_BID_LABEL         : '最低落札超え',
  ITEM_SEARCH_SOLD_OUT_LABEL                         : 'SOLD OUT以外',
  ITEM_SEARCH_RECOMMEND_LABEL                        : 'オススメ',
  ITEM_SEARCH_NEW_LABEL                              : 'NEW',
  ITEM_SEARCH_SEARCH_BUTTON_LABEL                    : '検索',
  ITEM_SEARCH_CONDITIONS_LABEL                       : '条件',
  ITEM_SEARCH_SEARCH_RESULTS_LABEL                   : '検索結果',
  ITEM_SEARCH_SEARCH_RESULTS_SUBJECT_LABEL           : '件',
  ITEM_SEARCH_SEARCH_RESULTS_NUMBER_SUBJECT_LABEL    : '件',
  ITEM_SEARCH_CATEGORY_SEARCH_RESULTS_NUMBER_LABEL   : '件',
  ITEM_SEARCH_PRINT_ITEMS_CSV_BUTTON_LABEL           : '出展機一覧リストCSV',
  ITEM_SEARCH_BULK_BIDDING_BUTTON_LABEL              : '一括入札',
  ITEM_SEARCH_CURRENT_PRICE_SORT_LABEL               : '現在価格',
  ITEM_SEARCH_RESULTS_LIMIT_LABEL                    : '表示件数',
  ITEM_SEARCH_BULK_BIDDING_MODAL_HEADER              : '一括入札',
  ITEM_SEARCH_CONFIRM_BIDDING_MODAL_HEADER           : '入札確認',
  ITEM_SEARCH_BULK_BIDDING_MODAL_BID_BUTTON_LABEL    : '入札',
  ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL : '閉じる',
  ITEM_SEARCH_TOTAL_ITEMS_LABEL                      : '件中',
  ITEM_SEARCH_NUMBER_OF_SHOWS_ITEMS_LABEL            : '件表示',

  ITEM_ROW_TOP_STATUS_LABEL                       : 'あなたがTOP',
  ITEM_ROW_SECOND_STATUS_LABEL                    : '2位応札',
  ITEM_ROW_EXCEEDING_THE_LOWEST_BID_STATUS_LABEL  : '最低落札超え',
  ITEM_ROW_MORE_LITTLE_LABEL                      : 'あと少し',
  ITEM_ROW_CANCEL_LABEL                           : '出品停止中',
  ITEM_ROW_LOWEST_BID_PRICE_LABEL                 : '最低落札価格',
  ITEM_ROW_AUCTION_STATUS_LABEL                   : 'オークション',
  ITEM_ROW_MAKER_LABEL                            : 'メーカー',
  ITEM_ROW_YYYY_LABEL                             : '年式',
  ITEM_ROW_YYYY_UNIT_LABEL                        : '',
  ITEM_ROW_YYYY_NOT_CLEAR_LABEL                   : '',
  ITEM_ROW_MDLGR_LABEL                            : 'モデル',
  ITEM_ROW_OPETIM_LABEL                           : 'アワメータ',
  ITEM_ROW_OPETIM_NOT_CLEAR_LABEL                 : '',
  ITEM_ROW_ACTSERNO_LABEL                         : '号機',
  ITEM_ROW_CURRENT_PRICE_LABEL                    : '現在価格',
  ITEM_ROW_START_PRICE_LABEL                      : '最低入札価格',
  ITEM_ROW_PRICE_CURRENCY_MARK                    : '￥',
  ITEM_ROW_START_STATUS_LABEL                     : '開始',
  ITEM_ROW_END_STATUS_LABEL                       : '終了予定',
  ITEM_ROW_ENDED_STATUS_LABEL                     : '終了',
  ITEM_ROW_SELLING_PRICE_LABEL                    : '販売価格',
  ITEM_ROW_SOLD_OUT_LABEL                         : 'SOLD OUT',
  ITEM_ROW_BID_PRICE_LABEL                        : '入札価格',
  ITEM_ROW_BID_BUTTON_LABEL                       : '入札',
  ITEM_ROW_MEMBERS_ONLY_LABEL                     : '会員限定',
  ITEM_ROW_LOGIN_LINK_LABEL                       : 'ログイン',
  ITEM_ROW_NEW_MEMBER_REGISTRATION_BUTTON_LABEL   : '新規会員登録(無料)',
  ITEM_ROW_DETAIL_LINK_LABEL                      : '詳細',
  ITEM_ROW_BULK_BIDDING_MODAL_DELETE_BUTTON_LABEL : '削除',
  ITEM_ROW_BID_SUCCESS_MESSAGE                    : '入札を受け付けました。',
  ITEM_ROW_CANCEL_SUCCESS_MESSAGE                 : '入札取り消しを受け付けました。',
  ITEM_ROW_BID_CONFIRM_MESSAGE                    : 'お間違いなければ入札ボタンをクリックしてください。',
  ITEM_ROW_MULTIPLE_ITEMS_LABEL                   : '台セット',

  ITEM_ROW_OVER_DOUBLE_CURRENT_PRICE_CONFIRM_MESSAGE_1     : '現在価格の2倍以上の金額が指定されています。',
  ITEM_ROW_OVER_DOUBLE_CURRENT_PRICE_CONFIRM_MESSAGE_2     : '入札してよろしいですか？',
  ITEM_ROW_OVER_DOUBLE_CURRENT_PRICE_CONFIRM_BUTTON        : 'OK',
  ITEM_ROW_OVER_DOUBLE_CURRENT_PRICE_CANCEL_BUTTON         : 'キャンセル',

  AUCTION_DETAIL_PAGE_NAME : 'オークション詳細',
  STOCK_DETAIL_PAGE_NAME   : '在庫機詳細',
  TENDER_DETAIL_PAGE_NAME  : 'テンダー詳細',

  DETAIL_LOWEST_BID_ACCEPT_PRICE_LABEL           : '最低入札価格',
  DETAIL_BID_STATUS_LABEL                        : '入札状況',
  DETAIL_BID_STATUS_UNBID_LABEL                  : '未入札',
  DETAIL_BID_STATUS_BIDED_LABEL                  : '入札済み',
  DETAIL_BID_STATUS_MORE_LITTLE_LABEL            : 'あと少し',
  DETAIL_BID_STATUS_SUCCESSFUL_BID_RIGHT_LABEL   : '落札権あり',
  DETAIL_BID_AUTOMATIC_BIDDING_LABEL             : '自動入札',
  DETAIL_DOWNLOAD_IMAGES_BUTTON_LABEL            : '画像を一括ダウンロード',
  DETAIL_CONTACT_PRODUCT_BUTTON_LABEL            : 'この製品について問い合わせる',
  DETAIL_DOWNLOAD_INSPECTION_REPORT_BUTTON_LABEL : '検品書をみる',
  DETAIL_PRODUCT_INFORMATION_LABEL               : '製品情報',
  DETAIL_AWAMETER_LABEL                          : 'アワメータ',
  DETAIL_MILEAGE_LABEL                           : '走行距離',
  DETAIL_STOCK_LOCATION_LABEL                    : '在庫場所',
  DETAIL_VEHICLE_INSPECTION_LABEL                : '車検',
  DETAIL_MAXIMUM_RATED_TOTAL_LOAD_LABEL          : '最大定格総荷重',
  DETAIL_CR_INSPECTION_LABEL                     : 'CR検査',
  DETAIL_SPECIFICATION_LABEL                     : '仕様',
  DETAIL_HOOK_LABEL                              : 'フック',
  DETAIL_OUTRIGGER_LABEL                         : 'アウトリガー',
  DETAIL_REMARKS_LABEL                           : '備考',
  DETAIL_CONTACT_PRODUCT_BOTTOM_BUTTON_LABEL     : '製品に関するお問い合わせ',
  DETAIL_BACK_TO_ITEM_SEARCH_BUTTON_LABEL        : '検索結果に戻る',
  DETAIL_NOTE_TEXT_TOP                           : '置き場現状有姿渡し・先売優先・保証なし',
  DETAIL_NOTE_TEXT_BOTTOM                        : '重要：保管場所にご注意ください。',
  DETAIL_LIST_OF_STORAGE_LOCATIONS_BUTTON_LABEL  : '保管場所一覧',
  DETAIL_ITEM_LOT_ITEMS_PS_TEXT                  : '※このロットには複数の製品が含まれています。',
  DETAIL_ITEM_PREV_BUTTON_LABEL                  : '前へ',
  DETAIL_ITEM_NEXT_BUTTON_LABEL                  : '次へ',
  DETAIL_ITEM_SNS_SHARE_LABEL                    : 'この建機をシェアする',
  DETAIL_ITEM_HOLDING_PERIOD_LABEL               : '開催期間',
  DETAIL_ITEM_SNS_SHARE_TEXT                     : '',
  DETAIL_ITEM_BID_REMAINING_TIME                 : '残り時間',
  DETAIL_ITEM_BID_EXTENDED_END                   : '延長終了',
  DETAIL_ITEM_INSPECTION_REPORT_LABEL            : '検品書',
  DETAIL_ITEM_STOCK_INQUIRY_LABEL                : '問い合わせ',

  CONTACT_PAGE_NAME                  : 'お問い合わせ',
  ITEM_CONTACT_PAGE_NAME             : '中古建機のお問い合わせ',
  CONTACT_FORM_INFO_TEXT             : '以下の項目をご入力ください。<em class="req">必須</em>のついている項目は必ずご入力ください。',
  CONTACT_FORM_CONTACT_CONTENT_LABEL : 'お問い合わせ内容',
  CONTACT_EXPORT_FILE_LABEL          : '添付ファイル',
  CONTACT_FORM_GUEST_TEXT            : 'ゲスト',
  CONTACT_FORM_UDRCARR_LABEL         : '足回り',
  CONTACT_FORM_CAB_LABEL             : '運転室',
  CONTACT_FORM_CLASS_LABEL           : 'クラス',
  CONTACT_FORM_AREA_LABEL            : '保管場所',
  CONTACT_COMPLETION_MESSAGE         : 'お問い合わせが完了しました。',

  MY_PAGE_SUCCESSFUL_BID_HISTORY_SEARCH_CONDITION_VIEW_HEADER : '落札履歴　検索条件',
  MY_PAGE_SEARCH_CONDITION_METHOD_LABEL                       : '方式',
  MY_PAGE_SEARCH_CONDITION_BIDDING_DATE_LABEL                 : '入札会終了日',
  MY_PAGE_SEARCH_CONDITION_BIDDING_FROM_DATE_PLACEHOLDER      : '例)2021/01/01',
  MY_PAGE_SEARCH_CONDITION_BIDDING_TO_DATE_PLACEHOLDER        : '例)2021/01/01',
  MY_PAGE_SEARCH_BUTTON_LABEL                                 : '検索',
  MY_PAGE_SEARCH_CONDITION_LABEL                              : '条件',
  MY_PAGE_SEARCH_RESULT_LABEL                                 : '検索結果',
  MY_PAGE_SEARCH_RESULT_COUNT_LABEL                           : '{count}件',
  MY_PAGE_SEARCH_RESULT_DISPLAY_COUNT_LABEL                   : '表示件数',
  MY_PAGE_SEARCH_RESULT_SORT_DISPLAY_COUNT_LABEL              : '{display_count}件',
  MY_PAGE_ITEM_BIDDING_END_DATETIME_LABEL                     : '入札会終了日時',
  MY_PAGE_COLON_LABEL                                         : ':',
  MY_PAGE_ITEM_MAKER_LABEL                                    : 'メーカー',
  MY_PAGE_ITEM_MODEL_LABEL                                    : 'モデル',
  MY_PAGE_ITEM_YEAR_LABEL                                     : '年式',
  MY_PAGE_ITEM_OPERATING_TIME_LABEL                           : 'アワメータ',
  MY_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL                      : '号機',
  MY_PAGE_ITEM_CONTRACT_PRICE_LABEL                           : '落札価格',
  MY_PAGE_ITEM_BID_PRICE_LABEL                                : '入札価格',
  MY_PAGE_ITEM_CONTRACT_BIDDER_LABEL                          : '最終入札者',
  MY_PAGE_ITEM_PRODUCT_IMAGE_DOWNLOAD_BUTTON_LABEL            : '商品画像ダウンロード',
  MY_PAGE_SHOW_MORE_BUTTON_LABEL                              : 'もっと見る',
  MY_PAGE_PRICE_CURRENCY_MARK                                 : '￥',
  MY_PAGE_YEAR_MARK                                           : '',
  MY_PAGE_NO_MARK                                             : 'No.',
  MY_PAGE_SHOW_ITEMS_LINK_LABEL                               : 'ロットの内訳',
  MY_PAGE_HIDE_ITEMS_LINK_LABEL                               : '内訳を閉じる',
  MY_PAGE_UPDATE_FILE_SIZE_LIMIT_ERROR_MESSAGE                : '5MB以内のファイルをアップロードしてください。',
  MY_PAGE_HEADER_BID_HISTORY_TEXT                             : '落札履歴',
  MY_PAGE_HEADER_CHANGE_INFO_TEXT                             : '会員情報編集',

  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_HEADER_TEXT      : '確認ダイアログ',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_MESSAGE_TEXT     : '退会してもよろしいでしょうか',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_OK_LABEL         : '確認',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL     : '閉じる',

  OTHERS_ORDER_RULE_TEXT                                  : '取引条件',
  OTHERS_ORDER_INTRO_RULE_TEXT                            : '弊社製品のご購入を希望されるお客様は、次の点にご同意いただいたうえでお取引いただくこととなります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_TEXT                     : '安全保障貿易管理に関して',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_DESCRIPTION_TEXT         : '弊社は、大量破壊兵器等の拡散防止、通常兵器の過度の蓄積防止、国際的な平和及び安全の維持の観点から、安全保障貿易管理を実施しています。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_TEXT               : '輸出管理方針',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_DESCRIPTION_1_TEXT : '「外国為替及び外国貿易法」等の輸出関連法規（以下、「外為法等」といいます。）及び米国製品の再輸出に関する法令を遵守し、これらに違反して貨物の輸出もしくは技術の提供をいたしません。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_DESCRIPTION_2_TEXT : '該非判定、顧客審査及びこれらを総合的に勘案した取引審査についての手続きを明確に規定し確実に実施致します。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_DESCRIPTION_3_TEXT : '取引に際しては、貨物・技術の用途および最終顧客の確認を実施し、大量破壊兵器および武器などの通常兵器関連の拡散防止に努めます。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_TEXT               : 'お取引内容の確認',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_DESCRIPTION_1_TEXT : '上記A)の方針に従い、お客様とのお取引内容を確認し、取引審査を実施させていただく場合があります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_DESCRIPTION_2_TEXT : '審査結果により、弊社指定条件を付してのお取引もしくはお取引そのものを控えさ せていただく場合があります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_DESCRIPTION_3_TEXT : '審査状況により、お引渡し期日が遅れる場合がありますので、あらかじめご了承ください。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_TEXT               : '「輸出管理に関する留意事項」へのご署名',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_DESCRIPTION_1_TEXT : '上記B)の実施と共に、弊社が発行する「輸出管理に関する留意事項」にご署名していただく事がお取引条件となっております。書面は必要に応じて弊社から郵送させていただきます。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_DESCRIPTION_2_TEXT : 'また、当該書面は、外為法等の改正、弊社との取引実績等、総合的に勘案し定期的に更新のご署名をしていただくことになります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_DESCRIPTION_3_TEXT : '新規でお取引開始の場合は貴社古物商許可書のコピーの弊社への提出が必要となります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_2_TEXT                     : 'コンプライアンスに関して',
  OTHERS_ORDER_INTRO_RULE_MAIN_2_DESCRIPTION_TEXT         : '機械を輸出する場合にはご購入者様において、輸入国の環境、安全に関する全ての規制を遵守し、必要な措置 （製品の改造、政府承認の取得等）を取って戴くことになります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_TEXT                     : '販売条件に関して',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_1_TEXT : '機械は先売優先・現状有姿・機械保証なしでの販売となります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_2_TEXT : '従って、機械をご購入頂く際には、実機確認を強くお勧め致します。',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_3_TEXT : 'お支払いはご購入日より1週間以内に弊社指定の銀行口座に全額現金（日本円）にてお支払して戴きます。（電信送金）',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_4_TEXT : '機械の搬出はご購入日より1ヶ月以内にお願い致します。',

  OTHERS_PROFILE_TEXT                           : '会社概要',
  OTHERS_PROFILE_COMPANY_NAME_LABEL             : '会社名',
  OTHERS_PROFILE_COMPANY_NAME_TEXT              : 'コベルコ建機日本株式会社',
  OTHERS_PROFILE_COMPANY_NAME_OTHER_LABEL       : '英文社名',
  OTHERS_PROFILE_COMPANY_NAME_OTHER_TEXT        : 'Kobelco Construction Machinery Japan Co., Ltd',
  OTHERS_PROFILE_DIRECTOR_NAME_LABEL            : '代表取締役社長',
  OTHERS_PROFILE_DIRECTOR_NAME_TEXT             : '山本　明（やまもと　あきら）',
  OTHERS_PROFILE_ADDRESS_LABEL                  : '所在地',
  OTHERS_PROFILE_POST_MARK                      : '〒',
  OTHERS_PROFILE_OFFICE_KOBE_TEXT               : '神戸中古車センター',
  OTHERS_PROFILE_OFFICE_KOBE_ADDRESS_TTEXT      : '兵庫県神戸市中央区港島7丁目15',
  OTHERS_PROFILE_OFFICE_KOBE_TEL                : 'Tel : 078-303-1220',
  OTHERS_PROFILE_OFFICE_KOBE_FAX                : 'Fax : 078-303-1250',
  OTHERS_PROFILE_OFFICE_YOKOHAMA_TEXT           : '横浜中古車センター',
  OTHERS_PROFILE_OFFICE_YOKOHAMA_ADDRESS_TTEXT  : '神奈川県横浜市鶴見区大黒町2番32',
  OTHERS_PROFILE_OFFICE_YOKOHAMA_TEL            : 'Tel : 045-834-9994',
  OTHERS_PROFILE_OFFICE_YOKOHAMA_FAX            : 'Fax : 045-510-4126',
  OTHERS_PROFILE_CAPITAL_LABEL                  : '資本金',
  OTHERS_PROFILE_CAPITAL_TEXT                   : '160億円',
  OTHERS_PROFILE_STOCKHOLDER_LABEL              : '株主構成',
  OTHERS_PROFILE_STOCKHOLDER_TEXT               : '株式会社神戸製鋼所　100％',
  OTHERS_PROFILE_FOUNDED_LABEL                  : '設立',
  OTHERS_PROFILE_FOUNDED_TEXT                   : '1999年10月1日（現社名変更年月日）',
  OTHERS_PROFILE_LICENSE_LABEL                  : '古物商許可番号',
  OTHERS_PROFILE_LICENSE_TEXT1                  : '東京都公安委員会許可済',

  OTHERS_PROFILE2_COMPANY_NAME_LABEL             : '会社名',
  OTHERS_PROFILE2_COMPANY_NAME_TEXT              : 'コベルコ建機日本株式会社',
  OTHERS_PROFILE2_COMPANY_NAME_OTHER_LABEL       : '英文社名',
  OTHERS_PROFILE2_COMPANY_NAME_OTHER_TEXT        : 'Kobelco Construction Machinery Japan Co., Ltd',
  OTHERS_PROFILE2_DIRECTOR_NAME_LABEL            : '代表取締役社長',
  OTHERS_PROFILE2_DIRECTOR_NAME_TEXT             : '荒木 治郎（あらき　じろう）',
  OTHERS_PROFILE2_ADDRESS_LABEL                  : '所在地',
  OTHERS_PROFILE2_POST_MARK                      : '〒',
  OTHERS_PROFILE2_OFFICE_ICHIKAWA_TEXT           : '市川中古車センター',
  OTHERS_PROFILE2_OFFICE_ICHIKAWA_ADDRESS_TEXT   : '千葉県市川市二俣新町17',
  OTHERS_PROFILE2_OFFICE_ICHIKAWA_TEL            : 'Tel : 047-327-5505',
  OTHERS_PROFILE2_OFFICE_ICHIKAWA_FAX            : 'Fax : 047-327-5512',
  OTHERS_PROFILE2_OFFICE_KOBE_TEXT               : '神戸中古車センター',
  OTHERS_PROFILE2_OFFICE_KOBE_ADDRESS_TTEXT      : '兵庫県神戸市中央区港島7丁目15',
  OTHERS_PROFILE2_OFFICE_KOBE_TEL                : 'Tel : 078-303-0900',
  OTHERS_PROFILE2_OFFICE_KOBE_FAX                : 'Fax : 078-303-2622',
  OTHERS_PROFILE2_CAPITAL_LABEL                  : '資本金',
  OTHERS_PROFILE2_CAPITAL_TEXT                   : '490百万',
  OTHERS_PROFILE2_STOCKHOLDER_LABEL              : '株主構成',
  OTHERS_PROFILE2_STOCKHOLDER_TEXT               : 'コベルコ建機（株）100％',
  OTHERS_PROFILE2_FOUNDED_LABEL                  : '設立',
  OTHERS_PROFILE2_FOUNDED_TEXT                   : '1999年4月1日',
  OTHERS_PROFILE2_LICENSE_LABEL                  : '古物商許可番号',
  OTHERS_PROFILE2_LICENSE_TEXT1                  : '千葉県公安委員会許可済',

  OTHERS_PRIVACY_TEXT                                         : '個人情報の保護',
  OTHERS_PRIVACY_MAIN_1_TEXT                                  : '個人情報の保護についての考え方',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_DESCRIPTION_TEXT           : 'コベルコ建機株式会社およびグループ会社（以下、「当社」という）は、お客様やお取引先を初めとする、当社とのお取引を通じ、当社に個人情報をご提供される全ての方（以下、「ご本人」という）の個人情報の適正な保護を重大な責務と認識し、この責務を果たすために、次の方針の下で個人情報を取り扱います。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_SUB_1_TEXT                 : '個人情報に適用される個人情報の保護に関する法律（以下、「個人情報保護法」という）その他の関係法令を遵守するとともに、一般に公正妥当と認められる個人情報の取り扱いに関する慣行に準拠し、適切に取り扱います。また、適宜、取り扱いの改善に努めます。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_SUB_2_TEXT                 : '個人情報の取り扱いに関する規程を明確にし、従業者に周知徹底します。また、委託先に対しても必要かつ適切な監督を行います。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_SUB_3_TEXT                 : '個人情報の取り扱いに際しては、利用目的を特定して通知または公表し、その利用目的に従って個人情報を取り扱います。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_SUB_4_TEXT                 : '個人情報の漏洩、紛失、改竄等を防止するため、必要な対策を講じて適切な管理を行います。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_SUB_5_TEXT                 : '保有する個人情報について、ご本人からの開示、訂正、削除、利用停止の依頼を所定の窓口でお受けして、誠意をもって対応します。',
  OTHERS_PRIVACY_MAIN_2_TEXT                                  : '個人情報の利用目的',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_2_DESCRIPTION_TEXT           : '当社は、法令等に照らし適正な手段により取得した個人情報につき、当社が営む事業において以下の目的のため必要な範囲で利用させていただくことがあります。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_2_SUB_1_TEXT                 : '製品・サービスのご案内、提供のため',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_2_SUB_2_TEXT                 : '製品・サービスの開発のため',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_2_SUB_3_TEXT                 : '原材料・物品・サービスの購入のため',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_2_SUB_4_TEXT                 : 'ご本人からの問い合わせへの対応のため',
  OTHERS_PRIVACY_MAIN_3_TEXT                                  : '共同利用',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_3_DESCRIPTION_TEXT           : '当社は、氏名、住所、電話番号、電子メールアドレス、商品購入履歴、勤務先および役職等の個人データの項目について、前項の利用目的の範囲内で、後述するグループ企業間にて共同で利用させていただくことがあります。共同利用に関する責任は、当社が有し、お問い合わせ、苦情等は、下記受付窓口にて受付させていただきます。',
  OTHERS_PRIVACY_MAIN_4_TEXT                                  : '第三者への開示・提供',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_DESCRIPTION_TEXT           : '当社は、前項の共同利用や委託先への提供の場合および以下のいずれかに該当する場合を除き、個人データを第三者へ開示または提供しません。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_SUB_1_TEXT                 : 'ご本人の同意がある場合',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_SUB_2_TEXT                 : '統計的なデータ等本人を識別することができない状態で開示・提供する場合',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_SUB_3_TEXT                 : '法令に基づき開示・提供を求められた場合',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_SUB_4_TEXT                 : '人の生命、身体または財産の保護のために必要な場合であって、ご本人の同意を得ることが困難である場合',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_SUB_5_TEXT                 : '国または地方公共団体等が公的な事務を実施する上で、協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合',
  OTHERS_PRIVACY_MAIN_5_TEXT                                  : 'ホームページ上での管理',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_DESCRIPTION_TEXT           : '格納された個人情報は、安全保護措置をとることにより、情報の漏洩、改竄を防ぐとともに、万一の場合は、可能な限り速やかな対策を講じます。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_TITLE_1_TEXT           : 'セキュリテイについて',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_DESCRIPTION_1_TEXT     : '当社は個人情報保護対策としてSSL（Secure　Sockets　Layer）を導入しています。SSLとは、インターネット上に流れる情報を暗号化する技術であり、第三者への情報漏洩を防ぎます。個人情報を取り扱うページはSSL対応となります。SSL対応のページに関しては、URLが「http://」から「https://」に変更されていることをご確認ください。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_TITLE_2_TEXT           : 'クッキー（Cookie）の使用について',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_DESCRIPTION_2_TEXT     : 'セキュリテイ確保やお客様への適切な情報提供を目的として、「クッキー（Cookie）」を使用することがあります。クッキーとは、サーバーから利用者のブラウザに送信される情報であり、利用者のコンピューターのハードデイスク等に格納されることもあります。なお、クッキーはお客様を識別できる情報を含んではいません。お客様のブラウザの設定により、クッキーの受け取りを承諾するか拒否するかを決定することができます。但し、クッキーの受け取りを拒否した場合、当社が運営するサイト上のサービスがご利用になれなくなることがあります。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_TITLE_3_TEXT           : '閲覧記録について',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_DESCRIPTION_3_TEXT     : '当社が運営するサイトを利用した場合、アクセス状況がサーバー上に蓄積されます。この情報は、アクセス量の測定等、統計を取るために使用され、それ以外に使用することはありません。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_TITLE_4_TEXT           : 'Google Analyticsの使用について',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_DESCRIPTION_4_1_TEXT   : '当社は、当社が運営するサイトのアクセス状況、利用動向を把握するため、Google Inc.のGoogle Analyticsを使用しています。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_DESCRIPTION_4_2_TEXT   : 'Google Analyticsはクッキーを使用して、お客様を識別できる情報を含まずにデータを収集します。なお、Google Analyticsで情報を収集、処理する仕組みに関する情報は、Google Inc.のポリシーにてご確認いただけます。',
  OTHERS_PRIVACY_MAIN_6_TEXT                                  : '開示',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_6_DESCRIPTION_TEXT           : '当社の保有個人データに関して、ご本人自身の情報を開示ご希望される場合には、お申し出いただいた方がご本人である事を確認した上で、開示の必要があると判断された場合には、合理的な期間および範囲で回答します。',
  OTHERS_PRIVACY_MAIN_7_TEXT                                  : '訂正・削除等',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_7_DESCRIPTION_TEXT           : '当社の保有個人データに関して、ご本人の情報について訂正、追加または削除をご希望される場合には、お申し出いただいた方がご本人である事を確認した上で、事実と異なる内容があり、訂正、追加または削除の必要があると判断された場合には、合理的な期間および範囲で情報の訂正、追加または削除をします。',
  OTHERS_PRIVACY_MAIN_8_TEXT                                  : '利用停止・消去',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_8_DESCRIPTION_TEXT           : '当社の保有個人データに関して、ご本人自身の情報の利用停止または消去をご希望される場合には、お申し出いただいた方がご本人であることを確認した上で、個人情報保護法第27条の規定に従い、利用停止または消去の必要があると判断された場合、合理的な期間および範囲で利用停止または消去します。これらの情報の一部または全部を利用停止または消去した場合、不本意ながらご要望に沿ったサービスの提供ができなくなることがありますので、ご理解とご協力を賜りますようお願い申し上げます。（なお、関係法令に基づき保有しております情報については、利用停止または消去のお申し出に応じられない場合があります。）',
  OTHERS_PRIVACY_MAIN_9_TEXT                                  : '開示等の受付窓口',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_DESCRIPTION_TEXT           : '当社の保有個人データに関する、上記6. 7. 8. のお申し出およびその他の個人情報に関するお問い合わせは、以下の窓口にて受け付けます。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_1_TEXT                 : '受付窓口',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_2_TEXT                 : '個人情報をご提供いただいた際に当社より通知した受付窓口、もしくは当社とお取引がある場合には、お取引窓口にて受け付けます。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_3_TEXT                 : '受付手続におけるご本人確認の手続方法および手数料についての詳細は、お申し出いただいた際にご案内申し上げます。なお、受付窓口がお分かりにならない場合には、下記までお問い合わせください。',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_TH_1_TEXT                  : '受付窓口のお問い合わせ先',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_TD_1_TEXT                  : 'コベルコ建機株式会社　企画本部　コーポレートコミュニケーショングループ',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_TH_2_TEXT                  : '電話',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_TH_4_TEXT                  : '電子メール',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_4_TEXT                 : '制定：2016年4月1日',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_5_TEXT                 : '改訂：2018年7月5日',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_6_TEXT                 : 'コベルコ建機株式会社',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_7_TEXT                 : '代表取締役社長　山本 明',
  OTHERS_PRIVACY_MAIN_10_TEXT                                 : '当社のグループ企業',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_SUB_TITLE_1_TEXT          : '主な国内法人',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_1_SUB_DESCRIPTION_1_TEXT  : 'コベルコ建機日本（株）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_1_SUB_DESCRIPTION_2_TEXT  : 'コベルコ建機エンジニアリング（株）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_1_SUB_DESCRIPTION_3_TEXT  : 'コベルコ教習所（株）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_1_SUB_DESCRIPTION_4_TEXT  : 'コベルコ建機トータルサポート（株）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_SUB_TITLE_2_TEXT          : '主な海外法人',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_1_TEXT  : '神鋼建機（中国）有限公司（中国）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_2_TEXT  : '成都神鋼建機融資租賃有限公司（中国）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_3_TEXT  : '杭州神鋼建設機械有限公司（中国）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_4_TEXT  : 'Kobelco Construction Machinery Southeast Asia Co., Ltd.（タイ）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_5_TEXT  : 'Kobelco International (S) Co., Pte. Ltd.（シンガポール）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_6_TEXT  : 'Ricon Private Ltd.（シンガポール）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_7_TEXT  : 'PT. Daya Kobelco Construction Machinery Indonesia（インドネシア）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_8_TEXT  : 'Kobelco Construction Machinery Malaysia Sdn. Bhd.（マレーシア）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_9_TEXT  : 'Kobelco Construction Equipment India Pvt. Ltd.（インド）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_10_TEXT : 'Kobelco Construction Machinery Australia Pty. Ltd.（オーストラリア）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_11_TEXT : 'Kobelco Construction Machinery Middle East &amp; Africa Fzco.（U.A.E）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_12_TEXT : 'Kobelco Construction Machinery U.S.A. Inc.（米国）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_13_TEXT : 'Kobelco Construction Machinery Europe B.V.（オランダ）',

  GUIDE_PAGE_TITLE_1                  : 'WEB オークション',
  GUIDE_PAGE_TITLE_2                  : 'テンダー/商談の流れ',
  GUIDE_PAGE_STEP1_TITLE              : '会員登録',
  GUIDE_PAGE_STEP1_DESCRIPTION_1      : '入札会に参加をご希望される方はまず、webサイトで会員登録申請を行って下さい。',
  GUIDE_PAGE_STEP1_DESCRIPTION_2      : 'ご登録申請受付後に当社にて審査を致します。審査を完了された方には、メールにてログインIDをお送り致します。',
  GUIDE_PAGE_STEP1_DESCRIPTION_3      : '尚、審査にあたり以下の書類のご提出をお願いする事になりますので、予めご了承下さい。',
  GUIDE_PAGE_STEP1_NOTE_TITLE_1       : '審査パス',
  GUIDE_PAGE_STEP1_NOTE_DESCRIPTION_1 : '参加資格',
  GUIDE_PAGE_STEP1_NOTE_DESCRIPTION_2 : '会員で、入札会参加要領と輸出管理上の留意事項を理解し、弊社の審査にパスした方に限ります。',

  GUIDE_PAGE_STEP2_TITLE : '建機を探す',
  GUIDE_PAGE_STEP2_TAB_1 : 'オークション',
  GUIDE_PAGE_STEP2_TAB_2 : 'テンダー',
  GUIDE_PAGE_STEP2_TAB_3 : '在庫機商談',

  GUIDE_PAGE_STEP2_TAB_1_TITLE_1         : '閲覧期間と入札期間',
  GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_1 : '出品建機情報を事前に確認できる「閲覧期間」と、実際に入札ができる「入札期間」が設定されています。',
  GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_2 : '「閲覧期間」でも、お気に入り登録が可能ですので是非ご覧ください。',
  GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_3 : 'スケジュールを見る',
  GUIDE_PAGE_STEP2_TAB_1_2_TITLE_1       : 'オークションとは',
  GUIDE_PAGE_STEP2_TAB_1_2_DESCRIPTION_1 : '表示されている現在価格＋入札単位ごとの金額であれば入札可能な「競り上がり」方式です。',
  GUIDE_PAGE_STEP2_TAB_1_3_TITLE_3       : '終了時刻の延長',
  GUIDE_PAGE_STEP2_TAB_1_3_DESCRIPTION_1 : '終了直前N分以内に、現在価格が変わる入札があった場合、その機械に限り時間が延長される仕組みとなっております。（延長の有無、終了直前N分の値は、オークションによって異なります。）',
  GUIDE_PAGE_STEP2_TAB_1_4_TITLE_4       : '落札価格決定方法について',
  GUIDE_PAGE_STEP2_TAB_1_5_TITLE_5       : '自動入札',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_1 : '1番高い価格で入札した方が落札します。落札額は、２番目に入札価格が高い方の入札価格に１入札単位を足した金額が、自動的に決定されます。',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_2 : '（例）2番目に高い入札額650万円 ＋ 1入札単位（10万円）＝ 落札額660万円',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_3 : '例：スタート価格400万円、',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_4 : '最低落札価格600万円、',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_5 : '入札単位10万円の場合',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_6 : '入札例',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_7 : '２番目に入札価格が高い650万円＋１入札単位（10万円）が現在価格として表示されます。',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_8 : 'このまま終了した場合、落札者は、Aさんで落札額は660万円となります。',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_9 : 'オークション出展機一覧を見る',
  GUIDE_PAGE_STEP2_TAB_2_TITLE_1         : '閲覧期間と入札期間',
  GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_1 : '出品建機情報を事前に確認できる「閲覧期間」と、実際に入札ができる「入札期間」が設定されています。',
  GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_2 : '「閲覧期間」でも、お気に入り登録が可能ですので是非ご覧ください。',
  GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_3 : 'スケジュールを見る',
  GUIDE_PAGE_STEP2_TAB_2_2_TITLE_1       : 'テンダーとは',
  GUIDE_PAGE_STEP2_TAB_2_2_DESCRIPTION_1 : '現在価格が非公開となり、終了時刻に一番高い価格を入札していた方が落札となる「封印」方式となります。',
  GUIDE_PAGE_STEP2_TAB_2_3_TITLE_3       : '終了時刻の延長',
  GUIDE_PAGE_STEP2_TAB_2_3_DESCRIPTION_1 : 'テンダー方式は、延長はありません。',
  GUIDE_PAGE_STEP2_TAB_2_4_TITLE_4       : '落札価格決定方法について',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_1 : '落札額　＝　TOPの入札額',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_2 : '1番高い価格で入札した方が、そのまま落札額となる方式。',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_3 : '※最高額が複数の場合はより早く入札した入札者を落札者とします。',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_4 : 'テンダー出展機一覧を見る',
  GUIDE_PAGE_STEP2_TAB_3_TITLE_1         : '開催時期',
  GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_1 : '随時開催しておりますので、サイトからご確認ください。',
  GUIDE_PAGE_STEP2_TAB_3_TITLE_2         : '価格について',
  GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_2 : 'サイトに記載がない場合は、お問い合わせよりご連絡ください。',
  GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_3 : '在庫機一覧を見る',
  GUIDE_PAGE_STEP3_TITLE                 : '建機を問い合わせる',
  GUIDE_PAGE_STEP3_DESCRIPTION_1         : '機械についてご不明な点があれば、問い合わせフォームにてお問い合わせください。',
  GUIDE_PAGE_STEP3_DESCRIPTION_2         : '※実機の確認を希望される方は、事前に各事務所へのご連絡をお願い致します。',
  GUIDE_PAGE_STEP4_TITLE                 : '商談成立',
  GUIDE_PAGE_STEP4_DESCRIPTION_1         : 'オークション、テンダーに参加頂いた方には入札結果をメールにてご連絡申し上げます。',
  GUIDE_PAGE_STEP4_DESCRIPTION_2         : '結果発表日までに連絡がない場合、誠に申し訳ございませんが、弊社までご連絡下さい。',
  GUIDE_PAGE_STEP5_TITLE                 : 'お支払い',
  GUIDE_PAGE_STEP5_DESCRIPTION_1         : '請求書をご案内してから１週間以内に現金(電信送金）にてお支払い下さい。',
  GUIDE_PAGE_STEP5_DESCRIPTION_2         : '（注）応札後のキャンセルは如何なる理由であっても認められません。',
  GUIDE_PAGE_STEP5_DESCRIPTION_3         : '落札後、不当にキャンセルされた場合は、違約金として落札価格の20％をお支払い頂きます。',
  GUIDE_PAGE_STEP6_TITLE                 : '取引・搬出',
  GUIDE_PAGE_STEP6_DESCRIPTION_1         : '機械の引取、搬出は弊社へのご入金を確認後可能となります。',
  GUIDE_PAGE_STEP6_DESCRIPTION_2         : '機械の引取、搬出は落札のご連絡より1ヶ月以内にお願い致します。',
  GUIDE_PAGE_STEP6_DESCRIPTION_3         : '保証',
  GUIDE_PAGE_STEP6_DESCRIPTION_4         : '現状有姿での販売につきご購入後の保証は一切ございません。',
  GUIDE_PAGE_STEP6_DESCRIPTION_5         : '入札機リストに記載の仕様及び状態はあくまで参考であり、実機のご確認はお客様ご自身にてお願い致します。',

  FAQ_TITLE_TEXT                         : 'Q&A',
  FAQ_INTRO_QA_TEXT                      : 'お客様から、良くご質問される内容とその回答を掲載しております。<br>こちらで解決されない場合などはお気軽にお問い合わせください。',
  FAQ_LIST_QA_1_TITLE_TEXT               : '機械状態の確認をしたいのですが、検品は可能ですか？',
  FAQ_LIST_QA_1_CONTENT_TEXT1            : '可能です。機械は弊社の都合により移動させることがありますので、事前にお電話にてお問い合わせ下さい。',
  FAQ_LIST_QA_1_CONTENT_TEXT2            : '尚、売約済みの機械の検品はご遠慮願います。',
  FAQ_LIST_QA_2_TITLE_TEXT               : '機械の購入を検討しているのですが、機械の取り置き（購入予約）は可能ですか？',
  FAQ_LIST_QA_2_CONTENT_TEXT             : '弊社の販売は先売優先となっておりますので、機械の取り置き（購入予約）は受け付けておりません。',
  FAQ_LIST_QA_3_TITLE_TEXT               : '機械状態を教えてもらえますか？',
  FAQ_LIST_QA_3_CONTENT_TEXT             : '弊社入庫時にチェックした内容をお伝えすることは可能ですが、<br class="only_pc">記載以外の事項を含め機械状態を保証するものでは御座いませんので予めご了承下さい。<br>お客様自身による実機検品をお勧めしております。',
  FAQ_LIST_QA_4_TITLE_TEXT               : '全ての機械は神戸ヤード、市川ヤード、横浜ヤードにあるのですか？',
  FAQ_LIST_QA_4_CONTENT_TEXT             : '基本的には神戸ヤード、市川ヤード、横浜ヤードにありますが一部、外部に保管している場合があります。<br>機械をご覧になりたい場合は事前にお問い合わせ下さい。',
  FAQ_LIST_QA_5_TITLE_TEXT               : '機械に保証はついていますか？',
  FAQ_LIST_QA_5_CONTENT_TEXT             : '現状有姿での販売につきご購入後の保証は一切ありません。',
  FAQ_LIST_QA_6_TITLE_TEXT               : '支払条件はありますか？',
  FAQ_LIST_QA_6_CONTENT_TEXT             : 'はい、あります。ご購入日より1週間以内に弊社指定の銀行口座に全額現金（日本円）にてお支払して戴きます。（電信送金）',
  FAQ_LIST_QA_7_TITLE_TEXT               : '自身の配車手配で機械の引き取りは可能ですか？',
  FAQ_LIST_QA_7_CONTENT_TEXT             : 'はい、可能です。引き取り日時を事前にご連絡下さいます様お願い申し上げます。',
  FAQ_LIST_QA_8_TITLE_TEXT               : '機械を国内の指定搬入先まで運送手配可能ですか？',
  FAQ_LIST_QA_8_CONTENT_TEXT             : '手配は可能です。お打ち合わせの上、別途費用にて、ご指定場所へ搬送手配致します。<br>ご指定場所への搬送は機械の分解、積み下ろしなど追加費用が発生する場合があります。',
  FAQ_LIST_QA_9_TITLE_TEXT               : '機械の引き取りはいつできますか？',
  FAQ_LIST_QA_9_CONTENT_TEXT             : '入金確認後に引渡し可能です。引き取り日時、引き取り運送業者名を事前にご連絡下さいます様お願い申し上げます。<br>営業時間内に引き取りをお願い致します。（営業日の8:30～17:00まで）',
  FAQ_LIST_QA_10_TITLE_TEXT              : '機械の引き取り期限はありますか？',
  FAQ_LIST_QA_10_CONTENT_TEXT            : '機械の搬出はご購入日より1ヶ月以内にお願い致します。',
  FAQ_LIST_QA_11_TITLE_TEXT              : '購入した機械の返品は受け付けますか？',
  FAQ_LIST_QA_11_CONTENT_TEXT            : '返品は受け付けておりませんので、ご了承下さい。',
  FAQ_LIST_QA_12_TITLE_TEXT              : '国内での使用目的で新車購入も検討しているのですが。',
  FAQ_LIST_QA_12_CONTENT_TEXT            : 'お近くの営業所までお問い合わせください。',
  FAQ_LINK_SALES_TEXT                    : '国内営業所（コベルコ建機のホームページへ移動します',

  ACCESS_SHOP_LIST_TITLE_TEXT                      : 'アクセス',
  ACCESS_SHOP_LIST_ADDRESS_TITLE_TEXT              : '所在地',
  ACCESS_SHOP_LIST_TEL_TITLE_TEXT                  : '電話',
  ACCESS_SHOP_LIST_FAX_TITLE_TEXT                  : 'Fax',
  ACCESS_WORKING_TIME_TITLE                        : '営業時間',
  ACCESS_TOKUCHO_TITLE                             : '特徴',
  ACCESS_BY_CAR_TITLE                              : 'お車でのアクセス',
  ACCESS_BY_CAR_MAP_ALT                            : 'アクセスマップ',
  ACCESS_BY_TRAIN_TITLE                            : '電車でのアクセス',
  ACCESS_SHOP_LIST_ICHIKAWA_TEXT                   : '市川中古車センター（ショベル）',
  ACCESS_SHOP_LIST_ICHIKAWA_EXPLAIN_TEXT           : '部品供給拠点、整備工場、教習所も隣接しています。',
  ACCESS_SHOP_LIST_ICHIKAWA_ADDRESS_CONTENT_TEXT   : '〒272-0002<br>千葉県市川市二俣新町17',
  ACCESS_SHOP_LIST_ICHIKAWA_TEL_CONTENT_TEXT       : '047-327-5505',
  ACCESS_SHOP_LIST_ICHIKAWA_TEL_HREF_TEXT          : 'tel:047-327-5505',
  ACCESS_SHOP_LIST_ICHIKAWA_FAX_CONTENT_TEXT       : '047-327-5512',
  ACCESS_SHOP_LIST_ICHIKAWA_ACCESS_TEXT            : '市川中古車センターのアクセスを見る',
  ACCESS_SHOP_LIST_YOKOHAMA_TEXT                   : '横浜中古車センター（クレーン）',
  ACCESS_SHOP_LIST_YOKOHAMA_EXPLAIN_TEXT           : '新車販売営業所、整備工場と一体となった拠点です。',
  ACCESS_SHOP_LIST_YOKOHAMA_ADDRESS_CONTENT_TEXT   : '〒230-0053<br>神奈川県横浜市鶴見区大黒町2番32',
  ACCESS_SHOP_LIST_YOKOHAMA_TEL_CONTENT_TEXT       : '045-834-9994',
  ACCESS_SHOP_LIST_YOKOHAMA_TEL_HREF_TEXT          : 'tel:045-834-9994',
  ACCESS_SHOP_LIST_YOKOHAMA_FAX_CONTENT_TEXT       : '045-510-4126',
  ACCESS_SHOP_LIST_YOKOHAMA_ACCESS_TEXT            : '横浜中古車センターのアクセスを見る',
  ACCESS_SHOP_LIST_KOBE_TEXT                       : '神戸中古車センター（ショベル／クレーン）',
  ACCESS_SHOP_LIST_KOBE_EXPLAIN_TEXT               : '良質お買い得機からコベルコのサービス工場にて整備を施した特選中古車までお客様のニーズに対応した商品をご用意しております。',
  ACCESS_SHOP_LIST_KOBE_ADDRESS_CONTENT_TEXT       : '〒650-0045<br>兵庫県神戸市中央区港島7丁目15番地',
  ACCESS_SHOP_LIST_KOBE_TEL1_CONTENT_TEXT          : '078-303-0900',
  ACCESS_SHOP_LIST_KOBE_TEL1_HREF_TEXT             : 'tel:078-303-0900',
  ACCESS_SHOP_LIST_KOBE_TEL1_TITLE                 : '（ショベルその他）',
  ACCESS_SHOP_LIST_KOBE_TEL2_CONTENT_TEXT          : '078-303-1220',
  ACCESS_SHOP_LIST_KOBE_TEL2_HREF_TEXT             : 'tel:078-303-1220',
  ACCESS_SHOP_LIST_KOBE_TEL2_TITLE                 : '（クレーン）',
  ACCESS_SHOP_LIST_KOBE_FAX_CONTENT_TEXT           : '078-303-2622（ショベルその他）<br>078-303-1250（クレーン）',
  ACCESS_SHOP_LIST_KOBE_ACCESS_TEXT                : '神戸中古車センターのアクセスを見る',
  ACCESS_SHOP_LIST_ABOUT_USED_TEXT                 : '中古車センターについて',
  ACCESS_SHOP_LIST_ABOUT_USED1_TEXT                : '関東、関西を中心にきめ細かな対応を全国で実現。',
  ACCESS_SHOP_LIST_ABOUT_USED2_TEXT                : 'KOBELCOの建設機械は、低燃費や低騒音等の優れた性能、品質、耐久性等から国内外の中古市場でも高い評価を戴いています。',
  ACCESS_SHOP_LIST_ABOUT_USED3_TEXT                : 'コベルコ建機日本（株）中古車・レンタル本部は、KOBELCOブランド中古建機の国内外への販売を専門としており、関東（千葉県市川市、神奈川県横浜市）と関西（兵庫県神戸市）に常設の中古車センターで在庫を揃え、お客様の実機確認とお買い求めに供しております。インターネットを活用した在庫情報の提供や、各中古車センターでの定期的入札会も行っています。',
  ACCESS_SHOP_LIST_ABOUT_USED4_TEXT                : 'コベルコ建機グループ全体としても、国内の販売会社等主要拠点において中古在庫を保有して販売しています。また、海外現地法人とも連携し海外中古建機市場での販売体制構築にも努めております。',
  ACCESS_SHOP_LIST_ABOUT_USED_CENTER_TEXT          : '中古車センターについて',

  ACCESS_ICHIKAWA_CENTER_TITLE                     : '市川中古車センター',
  ACCESS_ICHIKAWA_ADDRESS                          : '〒272-0002<br>千葉県市川市二俣新町17',
  ACCESS_ICHIKAWA_TEL                              : '047-327-5505',
  ACCESS_ICHIKAWA_TEL_HREF                         : 'tel:047-327-5505',
  ACCESS_ICHIKAWA_FAX                              : '047-327-5512',
  ACCESS_ICHIKAWA_WORKING_TIME                     : '月～金　8:30～17:00（祝日は休業）<br>ご来場の際はお手数ですがお電話にてご確認ください。',
  ACCESS_ICHIKAWA_TOKUCHO_TITLE                    : '特徴',
  ACCESS_ICHIKAWA_TOKUCHO                          : '部品供給拠点、整備工場を併設している事から良品の中古機をご提供。',
  ACCESS_ICHIKAWA_CENTER_TEXT                      : '市川中古車センター',
  ACCESS_ICHIKAWA_BY_CAR_MAP_DIRECT1               : '京葉道路をご利用のお客様、<br>東京方面／千葉方面ともに、京葉道路「原木」ICから約10分',
  ACCESS_ICHIKAWA_BY_CAR_MAP_DIRECT2               : '東関東自動車道<br>東京方面からの場合、東関東自動車道「千鳥町」ICから約15分<br>千葉方面からの場合、東関東自動車道「湾岸習志野」ICから約25分',
  ACCESS_ICHIKAWA_BY_SHINKANSEN                    : '新幹線「東京駅」からのアクセス',
  ACCESS_ICHIKAWA_BY_SHINKANSEN_MAP_DIRECT1        : 'JR京葉線「東京駅」（各駅停車）←→「二俣新町駅」<br>JR京葉線「二俣新町駅」下車 徒歩 約10分',
  ACCESS_ICHIKAWA_BY_SHINKANSEN_MAP_DIRECT2        : '地下鉄東西線「大手町駅」←→「西船橋駅」<br>地下鉄東西線「西船橋駅」からのアクセス<br>タクシーご利用で約10分 約1,500円',
  ACCESS_ICHIKAWA_BY_SHINKANSEN_MAP_DIRECT3_LINK1  : 'JR「東京駅」及び成田空港からお越しの方はこちら',
  ACCESS_ICHIKAWA_BY_SHINKANSEN_MAP_DIRECT3_LINK2  : '地下鉄東西線ご利用の方はこちら',
  ACCESS_ICHIKAWA_BY_AIRPORT_HANEDA                : '羽田空港からのアクセス',
  ACCESS_ICHIKAWA_BY_AIRPORT_HANEDA_MAP_DIRECT     : 'リムジンバスご利用で<br>羽田空港←→東京ディズニーランド／JR京葉線「舞浜駅」<br>（各駅停車）←→「二俣新町駅」下車 徒歩約10分',
  ACCESS_ICHIKAWA_BY_AIRPORT_NARITA                : '成田空港からのアクセス',
  ACCESS_ICHIKAWA_BY_AIRPORT_NARITA_MAP_DIRECT     : 'リムジンバスご利用で<br>成田空港←→幕張ベイタウン／JR京葉線「海浜幕張駅」<br>（各駅停車）←→「二俣新町駅」下車 徒歩約10分',
  ACCESS_ICHIKAWA_HOTEL_TITLE                      : '周辺ホテルのご案内',
  ACCESS_ICHIKAWA_HOTEL_NAME                       : 'オリエンタルホテル 東京ベイ',

  ACCESS_YOKOHAMA_CENTER_TITLE                     : '横浜中古車センター',
  ACCESS_YOKOHAMA_ADDRESS                          : '〒230-0053<br>神奈川県横浜市鶴見区大黒町2番32',
  ACCESS_YOKOHAMA_TEL                              : '045-834-9994',
  ACCESS_YOKOHAMA_TEL_HREF                         : '045-834-9994',
  ACCESS_YOKOHAMA_FAX                              : '045-510-4126',
  ACCESS_YOKOHAMA_WORKING_TIME                     : '月～金　8:30～17:00（祝日は休業）<br>ご来場の際はお手数ですがお電話にてご確認ください。',
  ACCESS_YOKOHAMA_TOKUCHO                          : '新車販売営業所、整備工場と一体となった拠点です。',
  ACCESS_YOKOHAMA_BY_CAR_MAP_DIRECT                : '首都高速神奈川1号横羽線<br>生麦出口より 8分<br>汐入出口より 15分',
  ACCESS_YOKOHAMA_BY_TRAIN_DIRECTION1              : '鶴見駅（JR/京急）よりタクシーで15分',
  ACCESS_YOKOHAMA_BY_TRAIN_DIRECTION2              : '生麦駅（京急）より徒歩20分',

  ACCESS_KOBE_CENTER_TITLE                     : '神戸中古車センター',
  ACCESS_KOBE_ADDRESS                          : '〒650-0045<br>兵庫県神戸市中央区港島7丁目15番地<br> (ヤードはL-8前にあります。)',
  ACCESS_KOBE_TEL1_NAME                        : '（ショベル営業部）',
  ACCESS_KOBE_TEL1                             : '078-303-0900',
  ACCESS_KOBE_TEL1_HREF                        : 'tel:078-303-0900',
  ACCESS_KOBE_TEL2_NAME                        : '（クレーン営業部）',
  ACCESS_KOBE_TEL2                             : '078-303-1220',
  ACCESS_KOBE_TEL2_HREF                        : 'tel:078-303-1220',
  ACCESS_KOBE_FAX                              : '078-303-2622（ショベル営業部）<br>078-303-1250（クレーン営業部）',
  ACCESS_KOBE_WORKING_TIME                     : '月～金　8:30～17:00（祝日は休業）<br>ご来場の際はお手数ですがお電話にてご確認ください。',
  ACCESS_KOBE_TOKUCHO                          : '神戸港ポートアイランド内で、輸出に最適の立地。<br>展示機は全車洗車済み。',
  ACCESS_KOBE_BY_CAR_MAP_DIRECTION1            : '大阪方面からの場合、<br>阪神高速3号神戸線「生田川」ICから約10分',
  ACCESS_KOBE_BY_CAR_MAP_DIRECTION2            : '姫路方面からの場合、<br>阪神高速3号神戸線「京橋」ICから約10分。<br>神戸大橋をおりてすぐに左折、一つ目の信号を右折後直進、二つ目の信号ガソリンスタンド前を左折、そのまま直進すると到着します。',
  ACCESS_KOBE_BY_TRAIN1                        : '山陽新幹線「新神戸駅」からのアクセス',
  ACCESS_KOBE_BY_TRAIN1_DIRECTION1             : 'タクシーご利用で約20分 約2,000円<br>タクシーの運転手さんに「ポートアイランド中ふ頭・L-8バース前」までとお伝え下さい。',
  ACCESS_KOBE_BY_TRAIN1_DIRECTION2             : 'タクシーご利用の方はこちら（MKタクシー）',
  ACCESS_KOBE_BY_TRAIN2                        : 'JR・阪急・阪神・地下鉄 鉄道各社「三宮駅」からのアクセス',
  ACCESS_KOBE_BY_TRAIN2_DIRECTION1             : 'タクシーご利用で約15分 約1,700円<br>タクシーの運転手さんに「ポートアイランド中ふ頭・L-8バース前」までとお伝え下さい。',
  ACCESS_KOBE_BY_TRAIN2_DIRECTION2             : 'ポートライナーご利用の場合<br>「三宮駅」より約14分「中埠頭駅」下車後、徒歩約20分',
  ACCESS_KOBE_BY_TRAIN2_DIRECTION3_A1          : 'JRご利用の方はこちら',
  ACCESS_KOBE_BY_TRAIN2_DIRECTION3_A2          : 'JR「三宮駅」･神戸空港からポートライナーご利用の方はこちら',
  ACCESS_KOBE_BY_AIRPORT1                      : '関西国際空港及び大阪国際空港から三宮までのアクセス',
  ACCESS_KOBE_BY_AIRPORT1_DIRECTION1           : 'リムジンバスご利用で<br>関西国際空港←→三宮 約65分<br>大阪国際空港←→三宮 約40分',
  ACCESS_KOBE_BY_AIRPORT1_DIRECTION2           : '関西国際空港情報はこちら',
  ACCESS_KOBE_BY_AIRPORT2                      : '神戸空港からのアクセス',
  ACCESS_KOBE_BY_AIRPORT2_DIRECTION1           : 'タクシーご利用で約10分 約1,800円<br>タクシーの運転手さんに「ポートアイランド中ふ頭・L-8バース前」までとお伝え下さい。',
  ACCESS_KOBE_BY_AIRPORT2_DIRECTION2           : 'ポートライナーご利用の場合<br>「神戸空港駅」より約12分「中埠頭駅」下車後、徒歩約20分',
  ACCESS_KOBE_HOTEL_TITLE                      : '周辺ホテルのご案内',
  ACCESS_KOBE_HOTEL1_NAME                      : 'ポートピアホテル',
  ACCESS_KOBE_HOTEL2_NAME                      : 'ホテルパールシティ神戸',

  SYSTEM_ERROR_MESSAGE : 'システムエラーが発生しました。'
}
