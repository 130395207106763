const selectedItemStore = {
  namespaced : true,
  state      : {
    selectedItem : null,
  },

  mutations : {
    setSelectedItem(state, item) {
      state.selectedItem = item
    },
  },

  actions : {
    setSelectedItem({commit}, item) {
      commit('setSelectedItem', item)
    },
  },

  getters : {
    getSelectedItem : state => state.selectedItem,
  },
};

export default selectedItemStore
