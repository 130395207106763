import Vue from 'vue'
import Vuex from 'vuex'
import define from './api/define'
import selectedItemStore from './components/ItemList/selectedItemStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state : {
    loading : false,
    define
  },
  mutations : {},
  actions   : {},
  modules   : {
    selectedItemStore,
  },
})
